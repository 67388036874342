@font-face {
  font-family: 'branding';
  src: url('./assets/fonts/brandingTypefaceFamily/branding-bold-webfont.woff2') format('woff2'),
    url('./assets/fonts/brandingTypefaceFamily/branding-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'branding';
  src: url('./assets/fonts/brandingTypefaceFamily/branding-medium-webfont.woff2') format('woff2'),
    url('./assets/fonts/brandingTypefaceFamily/branding-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helveticaNow';
  src: url('./assets/fonts/helveticaNow/helveticaNowText/helveticanowtext-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/helveticaNow/helveticaNowText/helveticanowtext-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helveticaNow';
  src: url('./assets/fonts/helveticaNow/helveticaNowText/helveticanowtext-bold-webfont.woff2') format('woff2'),
    url('./assets/fonts/helveticaNow/helveticaNowText/helveticanowtext-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'helveticaNow';
  src: url('./assets/fonts/helveticaNow/helveticaNowText/helveticanowtext-mediumitalic-webfont.woff2') format('woff2'),
    url('./assets/fonts/helveticaNow/helveticaNowText/helveticanowtext-mediumitalic-webfont.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

/* @font-face {
  font-family: 'helveticaNow';
  src: url('./assets/fonts/helveticaNow/helveticaNowText/helveticanowtext-thinitalic-webfont.woff2') format('woff2'),
       url('./assets/fonts/helveticaNow/helveticaNowText/helveticanowtext-thinitalic-webfont.woff') format('woff');
  font-weight: 100;
  font-style: italic;
} */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EFEFEF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}